<template>
  <div class="company">
    <el-breadcrumb class="breadCrumb"
                   separator="/">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>操作员管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{ queryId ? '编辑' : '新增' }}</el-breadcrumb-item>
    </el-breadcrumb>
    <!--            <el-button class="idBtn" type="primary">获取验证码</el-button>:text="delayTime<60?'重新发送':'获取验证码1'"-->
    <el-form class="cardInfo mt20"
             ref="form"
             :rules="rules"
             :model="form">
      <el-header class="titleOne">操作员信息</el-header>
      <el-row>
        <el-form-item v-if="companyRole=='0'"
                      label="产品角色："
                      prop="productRoleListStr">
          <el-checkbox-group v-model="form.productRoleListStr">
            <el-checkbox label="2"
                         name="type">经办</el-checkbox>
            <el-checkbox label="3"
                         name="type">复核</el-checkbox>
            <el-checkbox label="1"
                         name="type"
                         :disabled="queryRole=='1'?true:false">管理员</el-checkbox>
          </el-checkbox-group>
          <p class="tips ml76">说明：管理员有且仅有一个，如选择“管理员”，提交成功后原管理员将会变更为操作员。</p>
        </el-form-item>
        <el-form-item label="原手机号："
                      prop="oldUserPhone"
                      v-if="form.productRoleListStr.toString().indexOf('1')>=0?true:false">
          <el-input v-model.trim="form.oldUserPhone"
                    class="input450"
                    placeholder="请输入原操作员手机号"
                    :disabled="queryRole=='1'?true:false"></el-input>
        </el-form-item>
        <el-form-item label="验证码："
                      prop="phoneCode"
                      v-if="form.productRoleListStr.toString().indexOf('1')>=0?true:false">
          <el-input style="width: 320px"
                    v-model="form.phoneCode"
                    class="input450"
                    placeholder="请输入短信验证码"></el-input>
          <DelayButton :num="form.oldUserPhone"
                       class="idBtn"
                       style="width: 120px"
                       ref="DelayButton" />
        </el-form-item>
        <el-form-item class="inlineBlock"
                      :label="queryRole=='1'?'新操作员证件：':'操作员证件：'"
                      prop="fontIdUrl">
          <el-upload class="avatar-uploader upImg"
                     action="#"
                     :auto-upload="false"
                     :on-change="(file, fileList) => {changeImgHead(file, fileList, 1)}"
                     :show-file-list="false">
            <img v-if="form.fontIdUrl"
                 :src="form.fontIdUrl"
                 class="avatar">
            <div v-else
                 class="icon">
              <em></em>
              <i>身份证头像页</i>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item class="inlineBlock"
                      prop="backIdUrl">
          <el-upload class="avatar-uploader upImg"
                     action="#"
                     :auto-upload="false"
                     :on-change="(file, fileList) => {changeImgHead(file, fileList, 2)}"
                     :show-file-list="false">
            <img v-if="form.backIdUrl"
                 :src="form.backIdUrl"
                 class="avatar">
            <div v-else
                 class="icon">
              <em></em>
              <i>身份证国徽页</i>
            </div>
          </el-upload>
          <a href="#javascript:;"
             class="upImgTip"
             @click="lookVisibleSave=true">查看示例</a>
        </el-form-item>
        <el-form-item label="电子邮箱："
                      prop="userEmail">
          <el-autocomplete class="input450"
                           style="width: 450px"
                           v-model="form.userEmail"
                           :fetch-suggestions="querySearch"
                           :placeholder="queryRole=='1'?'请输入新操作员电子邮箱':'请输入操作员电子邮箱'"
                           :trigger-on-focus="false">
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="手机号码："
                      prop="userPhone">
          <el-input v-model.trim="form.userPhone"
                    class="input450"
                    :placeholder="queryRole=='1'?'请输入新操作员手机号码':'请输入操作员手机号码'"></el-input>
          <el-button v-if="form.productRoleListStr.toString().indexOf('1')>=0?true:false"
                     @click="IdCheck(type='u')"
                     class="idBtn"
                     type="primary">身份核验
          </el-button>
        </el-form-item>
        <el-form-item label="验证码："
                      prop="phoneCode"
                      v-if="form.productRoleListStr.toString().indexOf('1')>=0?false:true">
          <el-input style="width: 320px"
                    v-model="form.phoneCode"
                    class="input450"
                    placeholder="请输入短信验证码"></el-input>
          <DelayButton :num="form.userPhone"
                       class="idBtn"
                       style="width: 120px"
                       ref="DelayButton" />
        </el-form-item>
      </el-row>

      <el-header v-if="companyRole=='0'"
                 class="titleOne mt20">资料文件 <span>提示：点击下载
          <a v-if="queryRole=='1'"
             href="/static/zlbBG.zip"
             download="资料文件包">《资料文件包》</a>
          <a v-else
             href="/static/zlbADD.zip"
             download="资料文件包">《资料文件包》</a>，填写信息并加盖公章后再上传</span>
      </el-header>
      <el-row v-if="companyRole=='0'">
        <el-form-item label="法人授权委托书："
                      class="inlineBlocks"
                      label-width="156px"
                      prop="collectFile">
          <el-upload class="upload-demo"
                     action="#"
                     style="display:inline-block;"
                     :auto-upload="false"
                     :on-change="(file, fileList) => {upFile(file, fileList, 1)}"
                     :show-file-list="false"
                     multiple
                     :limit="3">
            <el-button class="upFileBtn"
                       size="small"
                       type="primary"><i></i>上传文件</el-button>
          </el-upload>
          <span v-if="form.collectFile == ''||form.collectFile == null"
                class="upFileTip">未选择文件</span>
          <span v-else
                class="upFileTip">上传成功! <em>点击查看
              <a id="link"
                 :href="form.collectFile"
                 class="red"
                 target="_blank"
                 rel="noopener noreferrer"
                 style="word-break: break-all">《{{ collectFilefileName | ellipsis }}》</a></em></span>
          <span v-if="collectFile==true"
                class="red">上传失败，请重新上传！</span>
        </el-form-item>
        <el-form-item v-if="form.productRoleListStr.toString().indexOf('1')>=0?true:false"
                      label="变更授权委托书："
                      class="inlineBlocks"
                      label-width="156px"
                      prop="changeCollectFile">
          <el-upload class="upload-demo"
                     style="display: inline-block"
                     action="#"
                     :auto-upload="false"
                     :on-change="(file, fileList) => {upFile(file, fileList, 2)}"
                     multiple
                     :show-file-list="false"
                     :limit="3">
            <el-button class="upFileBtn"
                       size="small"
                       type="primary"><i></i>上传文件</el-button>
          </el-upload>
          <span v-if="!form.changeCollectFile"
                class="upFileTip">未选择文件</span>
          <span v-else
                class="upFileTip">上传成功! <em>点击查看 <a :href="form.changeCollectFile"
                 class="red"
                 id="link1"
                 target="_blank"><i style="word-break: break-all;">《{{ changeCollectFilefileName | ellipsis }}》</i></a></em></span>
          <span v-if="changeCollectFile=='2'"
                class="red">上传失败，请重新上传！</span>
        </el-form-item>
      </el-row>
      <div class="formBtn mt30">
        <el-button class="btn submit"
                   @click="addList(form)"
                   size="small"
                   type="primary"><i></i>提 交</el-button>
      </div>
    </el-form>
    <!--    查看示例-->
    <el-dialog :show-close="false"
               :visible.sync="lookVisibleSave"
               :destroy-on-close="true"
               width="740px"
               class="noHeader">
      <div class="contentTip"
           style="text-align: center;line-height: 30px;display: flex;justify-content: space-between">
        <div class="sfz">
          <div class="imgBoxTip">
            <img width="340px"
                 height="240px"
                 class="tipIcon"
                 src="@/assets/img/auth/frsfz1.png"
                 alt="">
          </div>
          <h4>身份证头像页</h4>
          <p>（请上传清晰的证照）</p>
        </div>
        <div class="sfz">
          <div class="imgBoxTip">
            <img width="340px"
                 height="240px"
                 class="tipIcon"
                 src="@/assets/img/auth/frsfz2.png"
                 alt="">
          </div>
          <h4>身份证国徽页</h4>
          <p>（请上传清晰的证照）</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DelayButton from "@/views/MSME/auth/accountSafe/DelayButton";
import { mapState } from "vuex";

export default {
  name: "Update",
  components: {
    DelayButton: DelayButton,
  },
  data() {
    return {
      queryId: this.$route.query.id,
      queryRole: this.$route.query.role,
      form: {
        productRoleListStr: [], //角色
        fontIdUrl: "", //身份证头像页
        backIdUrl: "", //身份证反面
        collectFile: "", //法人授权委托书
        changeCollectFile: "", //变更授权委托书
        oldUserPhone: "", //原手机号
        userPhone: "", //变更-手机号
        userEmail: "", //邮箱
        certificateIsLong: "", //身份证证件是否长期1是2否
        certificateStartDate: "", //身份证有效期开始日期
        certificateEndDate: "", //身份证有效期结束日期
        certificateSign: "", //签发机关
        certificateNation: "", //民族
        certificateAddress: "", //身份证地址
        certificateBirthday: "", //出生日期
      },
      collectFile: false, //法人授权委托书失败
      changeCollectFile: false, //变更授权委托书失败
      lookVisibleSave: false, //查看示例
      changeCollectFilefileName: "", //变更授权文件名称
      collectFilefileName: "", //法人授权文件名称
      emailArry: [
        //邮箱联想
        "@qq.com",
        "@sina.com",
        "@163.com",
        "@126.com",
        "@yahoo.com.cn",
        "@gmail.com",
        "@sohu.com",
      ],
      rules: {
        productRoleListStr: [
          { required: true, message: "此项必填", trigger: "change" },
        ],
        oldUserPhone: [
          { required: true, message: "此项必填", trigger: "blur" },
          {
            type: "number",
            message: "手机号格式不正确",
            trigger: "blur",
            transform(value) {
              let phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
              if (!phonereg.test(value)) {
                return false;
              } else {
                return Number(value);
              }
            },
          },
        ],
        phoneCode: [{ required: true, message: "此项必填", trigger: "blur" }],
        fontIdUrl: [{ required: true, message: "此项必填", trigger: "change" }],
        backIdUrl: [{ required: true, message: "此项必填", trigger: "change" }],
        userPhone: [
          { required: true, message: "此项必填", trigger: "blur" },
          {
            type: "number",
            message: "手机号格式不正确",
            trigger: "blur",
            transform(value) {
              let phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
              if (!phonereg.test(value)) {
                return false;
              } else {
                return Number(value);
              }
            },
          },
        ],
        userEmail: [
          { required: true, message: "此项必填", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              let Email = /^\w{1,64}@[a-z0-9\-]{1,256}(\.[a-z]{2,6}){1,2}$/i;
              if (Email.test(value) == false) {
                callback(new Error("邮箱格式不正确"));
              } else {
                callback();
              }
            },
          },
        ],
        collectFile: [
          { required: true, message: "此项必填", trigger: "change" },
        ],
        changeCollectFile: [
          { required: true, message: "此项必填", trigger: "change" },
        ],
      },
      trim() {
        this.form.userEmail = this.form.userEmail.replace(/^\s+|\s+$/gm, "");
      },
    };
  },
  filters: {
    //文件名称超出20个字...隐藏
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 20) {
        return value.slice(0, 20) + "... " + ".pdf";
      }
      return value;
    },
  },
  created() {
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  computed: {
    ...mapState([
      "companyRole", //公司角色
    ]),
  },
  methods: {
    //编辑
    async getDetail() {
      // console.log(this.form.productRoleListStr.toString(),'111')
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/operatorManage/getOperatorById",
        this.$qs.stringify({ operatorId: this.queryId })
      );
      if (res.code == 200) {
        this.form.productRole = toString(res.data.productRole);
        if (this.$route.query.role == "1") {
          //管理员1
          this.form.oldUserPhone = res.data.userPhone; //原手机号
          this.form.productRoleListStr = res.data.productRoleListStr; //角色
        } else {
          this.form = res.data;
        }
        let changeCollectFile = res.data.changeCollectFile;
        if (changeCollectFile) {
          let collectFile1 = changeCollectFile.substring(
            changeCollectFile.lastIndexOf("/") + 1
          );
          this.changeCollectFilefileName = collectFile1; //变更授权文件名称
        }

        let collectFile = res.data.collectFile;
        if (collectFile) {
          let collectFile2 = collectFile.substring(
            collectFile.lastIndexOf("/") + 1
          );
          this.collectFilefileName = collectFile2; //法人授权委托文件
        }
      }
    },
    //新增
    async addList() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        console.log(
          this.form.productRoleListStr.toString(),
          "111",
          this.form.productRoleListStr
        );

        let commitPath;
        if (
          !this.$route.query.id ||
          (this.$route.query.id && this.queryRole == "1")
        ) {
          commitPath = "/cpiaoju-auth/operatorManage/addOperator";
        } else {
          commitPath = "/cpiaoju-auth/operatorManage/updateOperator";
        }
        var userPhoneRet = this.form.userPhone;
        var arrList = {
          id: this.$route.query.id,
          oldUserPhone: this.form.oldUserPhone,
          phoneCode: this.form.phoneCode,
          fontIdUrl: this.form.fontIdUrl,
          backIdUrl: this.form.backIdUrl,
          changeCollectFile: this.form.changeCollectFile,
          collectFile: this.form.collectFile,
          userPhone: this.form.userPhone,
          userEmail: this.form.userEmail,
          productRole: this.form.productRoleListStr.toString(),
          certificateNumber: this.form.certificateNumber,
          realName: this.form.realName,
          certificateIsLong: this.form.certificateIsLong, //身份证证件是否长期1是2否
          certificateStartDate: this.form.certificateStartDate, //身份证有效期开始日期
          certificateEndDate: this.form.certificateEndDate, //身份证有效期结束日期
          certificateSign: this.form.certificateSign, //签发机关
          certificateNation: this.form.certificateNation, //民族
          certificateAddress: this.form.certificateAddress, //身份证地址
          certificateBirthday: this.form.certificateBirthday, //出生日期
        };
        if (this.queryRole == "1") {
          arrList.operType = "b";
        }
        let isMastUser =
          this.form.productRoleListStr.toString().indexOf("1") >= 0;
        console.log(this.queryRole);
        const { data: res } = await this.$auth.post(
          commitPath,
          this.$qs.stringify(arrList)
        );
        if (res.code == 200) {
          if (!this.$route.query.id) {
            this.form = {};
          }
          // this.$store.commit("getNum", userPhoneRet); //用户手机号
          // this.$store.state.userPhone = userPhoneRet;
          // sessionStorage.setItem("getUser", userPhoneRet);
          if (this.companyRole == "0") {
            if (isMastUser == true) {
              this.$router.push({ path: "/login" });
              this.$message.success("变更成功，请重新登录");
            } else {
              this.$router.push({ path: "/aside/operator" });
              this.$message.success(res.msg);
            }
          } else {
            if (isMastUser == true) {
              this.$router.push({ path: "/login" });
              this.$message.success("变更成功，请重新登录");
            } else {
              this.$router.push({ path: "/aside/operator" });
              this.$message.success(res.msg);
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 邮箱联想
    querySearch(queryString, cb) {
      let results = [];
      if (!queryString) return [];
      if (queryString.indexOf("@") > -1) {
        results = [];
        cb(results);
      } else {
        this.emailArry.forEach((item) => {
          results.push({
            value: queryString + item,
          });
        });
        cb(results);
      }
    },
    //身份验证
    async IdCheck(val) {
      let obj = {};
      let UfontIdUrl = false; //操作人身份证正面
      let UuserPhone = false; //操作人身份证正面
      if (val == "u") {
        this.$refs["form"].validateField("fontIdUrl", async (vaild) => {
          if (!vaild) {
            UfontIdUrl = true;
            return true;
          } else {
            UfontIdUrl = false;
            return false;
          }
        });
        this.$refs["form"].validateField("userPhone", async (vaild) => {
          if (!vaild) {
            UuserPhone = true;
            return true;
          } else {
            UuserPhone = false;
            return false;
          }
        });
        if (UfontIdUrl == true && UuserPhone == true) {
          obj.name = this.form.realName;
          obj.phone = this.form.userPhone;
          obj.idCard = this.form.certificateNumber;
          obj.companyName = sessionStorage.getItem("companyName");
          obj.type = val;
        } else {
          return false;
        }
      }
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/mobileCheck",
        this.$qs.stringify(obj)
      );
      if (res.code == 200) {
        this.$message.success("核验通过，已发送信息！");
      } else {
        this.$message.error(res.msg);
      }
    },
    //上传操作人证件
    changeImgHead(file, fileList, type) {
      var testMsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testMsg === "png";
      const extension2 = testMsg === "jpg";
      const extension3 = testMsg === "jpeg";
      // const isLt2M = file.size / 1024 < 100;
      // 判断图片的格式
      if (!extension && !extension2 && !extension3) {
        this.$message({
          message: "上传文件只能是 png、jpg、jpeg格式!",
          type: "warning",
        });
        fileList.pop(); //用于删除并返回数组的最后一个元素。
        return;
      }
      // 判断图片的大小
      // if (!isLt2M) {
      //   this.$message({
      //     message: '上传文件大小不能超过 100KB!',
      //     type: 'warning'
      //   });
      //   fileList.pop()
      //   return;
      // }
      var idCardSide;
      if (type == 1) {
        idCardSide = "front";
      } else {
        idCardSide = "back";
      }
      let paramList = new FormData();
      paramList.append("file", file.raw); //
      paramList.append("type", "2"); //
      paramList.append("idCardSide", idCardSide); //
      this.$auth.post("/cpiaoju-auth/ocr/upload", paramList).then((res) => {
        if (res.data.code == 200 && res.data.ocr.status !== "1004") {
          if (type == 1) {
            this.form.fontIdUrl = res.data.fileUrl;
            this.form.realName = res.data.ocr.full_name;
            this.form.certificateNumber = res.data.ocr.citizenship_number;
            this.form.certificateNation = res.data.ocr.nation; //操作人民族
            this.form.certificateBirthday = this.$moment(
              res.data.ocr.birth,
              "YYYYMMDD"
            ).format("YYYY-MM-DD"); //出生日期----
            this.form.certificateAddress = res.data.ocr.address; //身份证地址
          } else if (type == 2) {
            let timeStart = res.data.ocr.issue_date; //ocr
            let timeEnd = res.data.ocr.expiring_date;
            if (timeEnd == "" || timeEnd == null) {
              this.form.certificateIsLong = "1"; //身份证证件是否长期1是2否
            } else {
              var formatTimeStart = this.$moment(timeStart, "YYYYMMDD").format(
                "YYYY-MM-DD"
              );
              var formatTimeEnd = this.$moment(timeEnd, "YYYYMMDD").format(
                "YYYY-MM-DD"
              );
              this.form.certificateIsLong = "2"; //身份证证件是否长期1是2否
            }
            this.form.backIdUrl = res.data.fileUrl;
            this.form.certificateStartDate = formatTimeStart; //身份证开始日期
            this.form.certificateEndDate = formatTimeEnd; //身份证结束日期
            this.form.certificateSign = res.data.ocr.organization; //签发机关
          }
        } else {
          this.$message.error(res.data.ocr.message);
        }
      });
    },
    //上传文件
    upFile(file, fileList, type) {
      var testMsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testMsg === "pdf";
      const isLt50M = file.size / 1024 / 1024 < 50;
      // 判断图片的格式
      if (!extension) {
        this.$message({
          message: "上传文件只能是 pdf 格式!",
          type: "warning",
        });
        fileList.pop(); //用于删除并返回数组的最后一个元素。
        return;
      }
      // 判断图片的大小
      if (!isLt50M) {
        this.$message({
          message: "上传文件大小不能超过 50M!",
          type: "warning",
        });
        fileList.pop();
        return;
      }
      let paramList = new FormData();
      paramList.append("file", file.raw);
      this.$auth
        .post("/cpiaoju-auth/ocr/uploadFile", paramList)
        .then((res) => {
          if (res.data.code == 200) {
            if (type == 1) {
              this.form.collectFile = res.data.fileUrl;
              this.collectFilefileName = res.data.fileName;
            } else if (type == 2) {
              this.form.changeCollectFile = res.data.fileUrl;
              this.changeCollectFilefileName = res.data.fileName;
            }
          } else {
            this.collectFile = true;
            this.form.collectFile = "";
            this.$message.error(res.data.ocr.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.collectFile = true;
          this.$message.error("文件上传错误！");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.company {
  /deep/ .avatar-uploader .el-upload {
    position: relative;
    width: 150px;
    height: 150px;
    border: 1px dashed #d8d8d8;
    text-align: center;
  }

  .cardInfo {
    .el-row {
      padding: 20px 20px 0;
      box-sizing: border-box;
      background: #ffffff;
    }

    .ml76 {
      margin-left: 120px;
    }
  }

  .upImg {
    display: inline-block;

    .icon {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 40px;

      em {
        width: 36px;
        height: 36px;
        background: url("~@/assets/img/auth/tianjia_icon.png") no-repeat;
      }

      i {
        color: #999999;
        letter-spacing: 1px;
      }
    }
  }

  .upImgTip {
    margin-left: 12px;
    color: #c70009;
  }

  p.tips {
    color: #999999;
    line-height: 20px;
  }

  /deep/ .el-form-item__label {
    width: 126px;
    text-align: right;
  }

  .el-input.input394 {
    width: 394px;
  }

  .adress {
    /deep/ .el-select {
      width: 143px;
      margin-right: 10px;

      .el-input {
        width: 100%;
      }
    }

    /deep/ .el-input {
      width: 200px;
    }
  }

  .el-form-item.inlineBlock {
    display: inline-block;
    margin-right: 20px;
    /*margin-bottom: 0;*/

    /deep/ .el-form-item__content {
      display: inline-block;
      height: 154px;
    }
  }

  .ml76 {
    margin-left: 76px;
  }

  .upFileTip {
    color: #999999;
    margin-left: 10px;
  }

  /*按钮*/

  .upFileBtn {
    background: #ffffff;
    color: #c70009;
    border-color: #c70009;
    font-size: 14px;

    i {
      display: inline-block;
      width: 16px;
      height: 14px;
      background: url("~@/assets/img/auth/sc_02.png") no-repeat center;
      margin-right: 4px;
    }
  }

  .upFileBtn:hover {
    background: #c70009;
    border-color: #c70009;
    color: #ffffff;

    i {
      background: url("~@/assets/img/auth/sc_01.png") no-repeat center;
    }
  }

  /deep/ .el-form-item__error {
    left: 120px;
  }

  .el-form-item.inlineBlock,
  .inlineBlocks {
    /deep/ .el-form-item__error {
      left: 0;
    }
  }
}
</style>
<style>
.company .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.company .avatar-uploader .el-upload:hover {
  /*border-color: #409EFF;*/
}

.company .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.company .avatar {
  width: 150px !important;
  height: 150px !important;
  display: block;
}
</style>